<template>
  <div class="header">
    <div class="top" :class="{ bgHeadernav: bgHeaderNav }">
      <div class="box a-over-h">
        <div class="logo">
          <router-link to="/">
            <img class="a-fl" src="../assets/img/xinli_logo.png">
            <img class="a-fl" src="../assets/img/logo.png">
            <h3 class="a-fl">喜安心理线上施测系统</h3>
          </router-link>
        </div>
        <ul class="top-nav">
          <li id="home" :class="{ current: current === 'home' }"><router-link to="/home">首页</router-link></li>
          <li id="evaluation" :class="{ current: current === 'evaluation' }"><router-link to="/testcenter">测评中心</router-link></li>
          <li id="user" :class="{ current: current === 'user' }"><router-link to="/user">个人中心</router-link></li>
          <li id="news" :class="{ current: current === 'news' }"><router-link to="/news">资讯信息</router-link></li>
          <li id="about_us" :class="{ current: current === 'aboutus' }"><router-link to="/about_us">关于我们</router-link></li>
        </ul>
        <div v-if="token" class="login-out a-fr" @click="signOut"></div>
        <div v-else class="to-login a-fr" @click="toLogin">登录</div>
        <!-- <form class="search" v-show="searchshow">
          <div class="a-over-h">
            <input class="a-fl" type="text" placeholder="关键字搜索"
              v-model="search.keywords">
            <div class="icon-search a-fl"
              @click="searchFun"></div>
          </div>
        </form> -->
      </div>
    </div>
    <div class="header-blank" :class="{ hide: !bgHeaderNav }"></div>
    <!-- my confirm -->
    <my-confirm ref="myComfirm"></my-confirm>
  </div>
</template>

<script>
import MyConfirm from '@/components/MyConfirm'
import logo from '@/assets/img/img_logo.png'

export default {
  name: 'header-nav',
  data () {
    return {
      token: '',
      logo: '',
      search: {
        keywords: ''
      }
    }
  },
  props: {
    'current': {
      type: String,
      default: 'home'
    },
    'bgHeaderNav': {
      type: Boolean,
      default: true
    },
    'searchshow': {
      type: Boolean,
      default: false
    }
  },
  components: {
    MyConfirm
  },
  methods: {
    toLogin: function () {
      this.$router.push('/login')
    },
    signOut: function () {
      let confirm = this.$refs.myComfirm
      confirm.open('温馨提示', {
        content: '你确定要退出吗?',
        btn: ['取消', '确定']
      }, () => {
        confirm.hide()
      }, () => {
        confirm.hide()
        this.$layer.msg('退出登录成功！')
        setTimeout(() => {
          localStorage.removeItem('token')
          this.$router.push('/login')
        }, 1000)
      })
      return false
    },
    searchFun: function () {
      localStorage.setItem('keywords', this.search.keywords)
      this.$router.push('/news')
    }
  },
  created () {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.top{
  width: 100%;
  height: 84px;
  text-align: center;
}
.bgHeadernav{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: url('../assets/img/bg_topnav.png') no-repeat center center;
  background-size: 100% 100%;
}
.top-home{
  position: inherit;
}
.logo{
  margin: 10px 40px 10px 0;
  line-height: 40px;
  float: left;
}
.logo img{
  display: block;
  height: 40px;
  margin-right: 15px;
}
.logo h3{
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  font-weight: normal;
}
.top-nav{
  display: inline-block;
  width: 550px;
}
.top-nav li{
  width: 20%;
  float: left;
  line-height: 60px;
  text-align: center;
}
.top-nav li a{
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 16px;
}
.top-nav li.current a:after{
  content: '';
  display: block;
  height: 4px;
  width: 30px;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 6px;
  left: 50%;
  margin-left: -15px;
}
.search{
  margin: 12px 30px;
  float: right;
}
.search>div{
  width: 240px;
  height: 36px;
  box-sizing: border-box;
  border: solid #fff 1px;
  border-radius: 18px;
  overflow: hidden;
}
.search input{
  width: 190px;
  color: #fff;
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  border:none;
  outline: medium;
  background-color: transparent;
  padding: 0 20px;
  box-sizing: border-box;
}
.search .icon-search{
  display: block;
  width: 44px;
  height: 36px;
  background: url('../assets/icon/icon_search.png') no-repeat center center;
  cursor: pointer;
}
.login-out{
  float: right;
  width: 60px;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;
  background: url('../assets/icon/icon_loginout.png') no-repeat center center;
}
.to-login{
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 60px;
  cursor: pointer;
}
.header-blank{
  height: 84px;
}
</style>
