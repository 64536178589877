<template>
  <div class="bottom">
    <div class="box a-over-h">
      <div>Copyright © 2019 by Inner Peace & Happiness Co.,Ltd  All rights reserved.
        <a href="http://beian.miit.gov.cn/">苏ICP备2022009754号-3</a>
      </div>
      <div>
        <span>苏州喜安复能健康科技有限公司</span>
        <span>邮箱：{{websiteData.email}}</span>
        <span class="email">地址：{{websiteData.address}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-nav',
  data () {
    return {
      websiteData: {
        mobile: '',
        email: 'xarw365@163.com',
        address: '江苏省苏州市姑苏区东吴北路288号二楼'
      }
    }
  },
  created () {
    /* this.$post('foot_info').then((res) => {
      if (res.code === 1) {
        this.websiteData = res.data
      }
    }).catch((res) => {
      this.$layer.msg('网络异常，请稍后重试！')
    }) */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bottom{
  text-align: center;
  background-color: #414141;
  line-height: 1.6;
  color: #999;
  padding: 10px 0;
}
.bottom span{
  padding: 0 10px;
}
.bottom a{
  font-size: 13px;
  padding-left: 20px;
  color: #ff6a00;
}
</style>
